import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const primePage = lazy(() => import('views/prime/PrimePage'));

const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const primeRoute = {
  path: `${appRoot}/users-prime`,
  exact: true,
  icon: 'crown',
  label: 'prime.title',
  component: primePage,
  protected: true,
  roles: [USER_ROLE.Admin, USER_ROLE.Consultant],
};

export default primeRoute;
