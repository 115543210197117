import {
  LAYOUT,
  MENU_BEHAVIOUR,
  MENU_PLACEMENT,
  NAV_COLOR,
  RADIUS,
  THEME_COLOR,
} from 'constants.js';
import appName from 'shared/constants/appName';

export const IS_DEMO = false;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;
const CLIENT_NAME = appName;

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: `LAW | ${CLIENT_NAME}`,
  titleTemplate: `%s | ${CLIENT_NAME}`,
};

export const DEFAULT_PATHS = {
  APP: '/',
  CONSULTANCIES_REQUESTS: '/consultancies-requests',
  CHAT: '/chat',
  USERS_PRIME: '/users-prime',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  USER_WELCOME: '/dashboards/default',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Vertical,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Boxed,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightBlue,
  NAV_COLOR: NAV_COLOR.Default,
  USE_SIDEBAR: false,
};

export const DEFAULT_USER = {
  thumb: '/img/profile/profile-1.webp',
};

export const REDUX_PERSIST_KEY = 'law@project';
