import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const dashboards = {
  dash: lazy(() => import('views/dashboards/DashboardsPage')),
};

const dashRoute = {
  path: DEFAULT_PATHS.APP,
  exact: true,
  icon: 'board-1',
  label: 'menu.dashboard',
  component: dashboards.dash,
  roles: [USER_ROLE.Admin, USER_ROLE.Consultant],
  protected: true,
};

export default dashRoute;
