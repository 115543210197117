import consultantsRoute from 'views/consultants/routes';
import notificationsRoute from 'views/notification/routes';
import chatRoute from 'views/chat/routes';
import newAppointmentsRoute from 'views/newAppointments/routes';
import attendanceListRoute from 'views/AttendanceList/routes';
import dashRoute from 'views/dashboards/routes';
import primeRoute from 'views/prime/routes';

const routesAndMenuItems = {
  mainMenuItems: [
    dashRoute,
    newAppointmentsRoute,
    attendanceListRoute,
    notificationsRoute,
    consultantsRoute,
    primeRoute,
    chatRoute,
  ],
  sidebarItems: [],
};

export default routesAndMenuItems;
