import { db } from 'settings/firebaseConfig';
import {
  doc,
  query,
  where,
  getDoc,
  setDoc,
  getDocs,
  orderBy,
  deleteDoc,
  updateDoc,
  onSnapshot,
  collection,
  arrayUnion,
  runTransaction,
} from 'firebase/firestore';

const getDocument = async (collectionName, id) => {
  const docConfig = doc(db, collectionName, id);
  const docSnapshot = await getDoc(docConfig);

  if (docSnapshot.exists()) {
    return docSnapshot;
  }
  throw new Error('F_Error: Document not found');
};

const getDocuments = async (collectionName, additional) => {
  const docCollection = collection(db, collectionName);

  const docSnapshot = await getDocs(
    additional ? query(docCollection, additional) : docCollection
  );

  if (docSnapshot) {
    const documents = [];

    docSnapshot.forEach((docSnap) => {
      documents.push({ id: docSnap.id, ...docSnap.data() });
    });

    return documents;
  }

  throw new Error('F_Error: Documents not founds');
};

const updateDocument = async (collectionName, id, data) => {
  const docConfig = doc(db, collectionName, id);

  await updateDoc(docConfig, data);
};

const deleteDocument = async (collectionName, id) => {
  const docConfig = doc(db, collectionName, id);
  await deleteDoc(docConfig);
};

const setDataInDocument = async (collectionName, id, data, options) => {
  const docConfig = doc(db, collectionName, id);
  await setDoc(
    docConfig,
    data, // { consultancie: xxxx }
    options // example: { merge: true }
  );
};

const onRealtime = onSnapshot;

export {
  db,
  doc,
  query,
  where,
  orderBy,
  collection,
  onRealtime,
  arrayUnion,
  getDocument,
  getDocuments,
  runTransaction,
  updateDocument,
  deleteDocument,
  setDataInDocument,
};
