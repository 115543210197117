const notificationsEnMessages = {
  'notification.add': 'Create',
  'notification.addNotifications': 'Create Notification',
  'notification.errorMessage': 'An error has occurred! Try again',
  'notification.successMessage': 'Data was created successfully',
  'notification.message': 'Message',
  'notification.newMessage': 'New message',
  'notification.notifications': 'Notifications',
  'notification.period': 'Period',
  'notification.toProgramed': 'Programed to',
  'notification.public': 'Public',
  'notification.save': 'Send',
  'notification.situation': 'Situation',
  'notification.targetAudience': 'Target audience',
  'notification.block': 'Block',
  'notification.consultacie.modal.title': 'Consultancie requested',
  'notification.consultacie.modal.customer': 'Customer',
  'notification.consultacie.modal.subject': 'Subject',
  'notification.consultacie.modal.created.at': 'Date',
  'notification.consultacie.modal.button.confirm': 'Start support',
  'notification.consultacie.modal.button.close': 'Close',
  'notification.see.all': 'See all',
};

export default notificationsEnMessages;
