const consultantsEnMessages = {
  'consultants.can.edit.users.prime': 'Can edit users prime',
  'consultants.can.edit.users.prime.description':
    "If you enable the above option, this consultant will be able to manage the app's Prime users.",
  'consultants.consultant': 'Consultant',
  'consultants.views.chats': 'View chats',
  'consultants.start': 'Start',
  'consultants.attendances': 'Attendances',
  'consultants.full.name': 'Full name',
  'consultants.birth.date': 'Birth date',
  'consultants.email': 'E-mail',
  'consultants.add.new.consultant': 'Add new consultant',
  'consultants.basic.information': 'Basic information',
  'consultants.contacts': 'Contacts',
  'consultants.photo': 'Photo',
  'consultants.cell': 'Cell',
  'consultants.phone': 'Telephone (Optional)',
  'consultants.edit.consultant': 'Edit consultant',
  'consultants.delete.title': 'Delete consultant',
  'consultants.delete.description':
    'Do you really want to delete <b>{name}</b>?',
};

export default consultantsEnMessages;
