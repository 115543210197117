const AttendanceListEnMessages = {
  'attendance.attendance.list': 'Attendance list',
  'attendance.id': 'id',
  'attendance.client': 'Client',
  'attendance.start': 'Start',
  'attendance.end': 'End',
  'attendance.view.conversation': 'View conversation',
};

export default AttendanceListEnMessages;
