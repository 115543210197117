import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  db,
  query,
  where,
  arrayUnion,
  onRealtime,
  updateDocument,
  getDocument,
  setDataInDocument,
} from 'services/firebase/firestore/FirestoreService';

import { collection } from '@firebase/firestore';
import {
  DB_CONSULTANCIES,
  DB_CONSULTANCIES_REQUESTS,
  DB_USERS,
} from 'services/firebase/firestore/FirestoreTables';

const initialState = {
  status: 'idle',
  items: [],
};

export const startConsultancie = createAsyncThunk(
  'notification/startConsultancie',
  async ({ uid, consultancie_id: consultancieId }, { getState }) => {
    try {
      const { auth } = getState();

      await updateDocument(DB_CONSULTANCIES_REQUESTS, uid, {
        status: 'started',
      });

      await updateDocument(DB_CONSULTANCIES, consultancieId, {
        is_open: true,
        updated_at: new Date(),
        'users.consultant': {
          id: auth.currentUser.uid,
          name: auth.currentUser.displayName,
        },
      });

      await updateDocument(DB_USERS, auth.currentUser.uid, {
        attendances: arrayUnion(consultancieId),
      });

      const user = await getDocument(DB_USERS, uid);

      const { consultancies } = user.data();

      const currentConsultancie = consultancies.find(
        (consult) => consult.consultancie_id === consultancieId
      );

      const indexOf = consultancies.indexOf(currentConsultancie);

      currentConsultancie.started_at = new Date();
      consultancies[indexOf] = currentConsultancie;

      await setDataInDocument(
        DB_USERS,
        uid,
        { consultancies },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationsLoading(state) {
      state.status = 'loading';
    },
    notificationsLoaded(state, action) {
      if (action.payload) state.items = action.payload;
      state.status = 'idle';
    },
    notificationsNewMessages(state, action) {
      if (action.payload) state.newMessages = action.payload;
    },
  },
  extraReducers: {
    [startConsultancie.pending]: (state) => {
      state.status = 'loading';
    },
    [startConsultancie.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (action.payload) state.items = action.payload;
    },
    [startConsultancie.rejected]: (state) => {
      state.status = 'idle';
    },
  },
});

export const {
  notificationsLoading,
  notificationsLoaded,
  notificationsNewMessages,
} = notificationSlice.actions;

export const fetchConsultanciesRequests = createAsyncThunk(
  'notification/fetchConsultanciesRequests',
  async (_, { dispatch }) => {
    dispatch(notificationsLoading());
    try {
      const consultantRequestsQuery = query(
        collection(db, DB_CONSULTANCIES_REQUESTS),
        where('status', '==', 'pending')
      );

      onRealtime(consultantRequestsQuery, ({ docs }) => {
        const requests = docs.map((req) => req.data());
        dispatch(notificationsLoaded(requests));
      });
    } catch (error) {
      console.error(error);
      dispatch(notificationsLoaded());
    }
  }
);

export const fetchMessagesRequests = createAsyncThunk(
  'notification/fetchConsultanciesRequests',
  async (_, { dispatch }) => {
    dispatch(notificationsLoading());

    try {
      const consultantRequestsQuery = query(
        collection(db, DB_CONSULTANCIES_REQUESTS),
        where('not_read_web', '>', 0)
      );

      onRealtime(consultantRequestsQuery, ({ docs }) => {
        const requests = docs.map((req) => req.data());
        dispatch(notificationsNewMessages(requests));
      });
    } catch (error) {
      console.error(error);
    }
  }
);

const notificationReducer = notificationSlice.reducer;
export default notificationReducer;
