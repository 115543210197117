import commonsPtMessages from 'i18n/messages/commons/commonsPt';
import notificationsPtMessages from 'i18n/messages/notifications/notificationsPt';
import loginPtMessages from 'i18n/messages/login/loginPt';
import validationPtMessages from 'i18n/messages/validate/ValidationPt';
import consultantsPtMessages from 'i18n/messages/consultants/consultantsPt';
import chatPtMessages from 'i18n/messages/chat/chatPt';
import newAppointmentsPtMessages from 'i18n/messages/newAppointments/newAppointmentsPt';
import AttendanceListPtMessages from 'i18n/messages/AttendanceList/AttendanceListPt';
import dashboardsPtMessages from 'i18n/messages/dashboards/dashboardsPt';
import menuPtMessages from 'i18n/messages/menu/menuPt';
import primePtMessages from 'i18n/messages/prime/primePt';

const ptMessages = {
  ...commonsPtMessages,
  ...notificationsPtMessages,
  ...validationPtMessages,
  ...loginPtMessages,
  ...consultantsPtMessages,
  ...chatPtMessages,
  ...newAppointmentsPtMessages,
  ...AttendanceListPtMessages,
  ...dashboardsPtMessages,
  ...menuPtMessages,
  ...primePtMessages,
};

export default ptMessages;
