const dashboardsPtMessages = {
  'dashboards.welcome': 'Bem-vindo{symbol}',
  'dashboards.total.consultancies': 'Total de consultorias',
  'dashboards.total.users': 'Total de usuários',
  'dashboards.total.prime.list': 'Lista Prime',
  'dashboards.opened': 'Atendimentos em aberto',
  'dashboards.ended': 'Atendimentos encerrados',
  'dashboards.billing': 'Faturamento',
  'dashboards.sales': 'Vendas',
  'dashboards.consultants': 'Consultores',
  'dashboards.returns': 'Total de estornos',
  'dashboards.comments': 'Comentários',
  'dashboards.purchasing': 'Compras',
  'dashboards.called': 'Atendimentos',
  'dashboards.pending': 'Pendente',
  'dashboards.shipped': 'Enviado',
};

export default dashboardsPtMessages;
