import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  db,
  doc,
  query,
  where,
  arrayUnion,
  onRealtime,
  getDocument,
  deleteDocument,
  updateDocument,
  setDataInDocument,
} from 'services/firebase/firestore/FirestoreService';
import {
  DB_CONSULTANCIES,
  DB_CONSULTANCIES_REQUESTS,
  DB_USERS,
} from 'services/firebase/firestore/FirestoreTables';
import { USER_ROLE } from 'constants.js';
import { collection } from '@firebase/firestore';
import { sendFile } from 'services/firebase/storage/StorageService';
import { STORAGE_CHAT_BUCKET } from 'services/firebase/storage/StorageBucket';
import { sendNotification } from 'views/notification/notificationsSlice';

const initialState = {
  file: null,
  users: [],
  messages: [],
  userHistoricMessages: [],
  userAllConsultanciesFinished: false,
  newMessage: [],
  loading: false,
  loadingUsers: false,
  loadingFile: false,
  selectedChat: null,
  currentMode: 'chat', // chat or service
  currentCall: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages(state, { payload }) {
      state.messages = payload;
    },
    setUserHistoricMessages(state, { payload }) {
      state.userHistoricMessages = payload;
    },
    setUserAllConsultanciesFinished(state, { payload }) {
      state.userAllConsultanciesFinished = payload;
    },
    setFile(state, { payload }) {
      state.file = payload;
    },
    setUsers(state, { payload }) {
      state.users = payload;
    },
    setNewMessage(state, { payload }) {
      state.newMessage = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setLoadingUsers(state, { payload }) {
      state.loadingUsers = payload;
    },
    setLoadingFile(state, { payload }) {
      state.loadingFile = payload;
    },
    chatSetSelectedChat(state, { payload }) {
      state.selectedChat = payload;
    },
    chatSetCurrentCall(state, action) {
      state.currentCall = action.payload;
    },
    chatChangeMode(state, action) {
      state.currentMode = action.payload;
    },
    resetConsultancie(state) {
      state.messages = [];
      state.selectedChat = null;
      state.currentCall = null;
      state.currentMode = 'chat';
    },
  },
});
const {
  setUsers,
  setLoading,
  setMessages,
  setLoadingFile,
  setLoadingUsers,
  setUserHistoricMessages,
  setUserAllConsultanciesFinished,
} = chatSlice.actions;
export const { setFile } = chatSlice.actions;

export const {
  chatChangeMode,
  chatSetSelectedChat,
  chatSetCurrentCall,
  resetConsultancie,
} = chatSlice.actions;

export const getMyUsersConsultancies =
  ({ id }) =>
  async (dispatch, getState) => {
    const { auth: au } = getState();

    dispatch(setLoadingUsers(true));

    const queries = query(
      collection(db, DB_CONSULTANCIES),
      // where('is_open', '==', true),
      where('users.consultant.id', '==', au.currentUser.id)
    );

    onRealtime(queries, async ({ docs }) => {
      const consultancies = docs.map((docMessage) => ({
        consultancie_id: docMessage.id,
        ...docMessage.data(),
      }));

      const usersRequests = await Promise.all(
        consultancies.map(async (consultance) => {
          const {
            users,
            consultancie_id: consultancieId,
            updated_at: last,
            type,
            user_owner_id: userId,
            ticket_status: ticketStatus,
            is_open: isOpen,
          } = consultance;

          const userInfo = await getDocument(DB_USERS, userId);

          let userEmail = '-';
          let userIsPrime = false;

          if (userInfo.exists()) {
            userEmail = userInfo.data().email;
            userIsPrime = userInfo.data().is_prime ?? false;
          }

          return {
            consultancieId,
            isOpen,
            type,
            ticketStatus,
            userEmail,
            userIsPrime,
            last: last.toDate().toString(),
            ...users.owner,
          };
        })
      );

      const usersSort = usersRequests.sort((left, right) => {
        const leftDate = new Date(left.last);
        const rightDate = new Date(right.last);

        return leftDate.getTime() - rightDate.getTime();
      });

      usersSort.reverse();

      const excludeEmails = [];
      const filterUsers = [];

      usersRequests.forEach((element) => {
        const { userEmail } = element;
        if (!excludeEmails.includes(userEmail)) {
          filterUsers.push(element);
          excludeEmails.push(userEmail);
        }
      });

      dispatch(setUsers(filterUsers));
      if (id) {
        const userSelected = filterUsers.find(
          (user) => user.consultancieId === id
        );

        if (userSelected) dispatch(chatSetSelectedChat(userSelected));
        id = null;
      }
      dispatch(setLoadingUsers(false));
    });
  };

export const getHistoricByUser = createAsyncThunk(
  'chat/getHistoricByUser',
  async ({ role, consultancieId, userId }, { dispatch }) => {
    let userIdentify;

    if (consultancieId) {
      const consultancie = await getDocument(DB_CONSULTANCIES, consultancieId);
      const {
        users: { owner },
      } = consultancie.data();
      userIdentify = owner.id;
    }

    const user = await getDocument(DB_USERS, userId ?? userIdentify);
    const { consultancies } = user.data();

    if (consultancies.length <= 1) return;

    const consultsStarteds = consultancies.filter(
      ({ started_at: startedAt }) => {
        return !!startedAt;
      }
    );

    const consultanciesFinisheds = consultsStarteds.filter(
      ({ finished_at: finishedAt }) => {
        return !!finishedAt;
      }
    );

    const historic = [];

    await Promise.all(
      consultanciesFinisheds.map(async (item) => {
        try {
          const consultance = await getDocument(
            DB_CONSULTANCIES,
            item.consultancie_id
          );

          const { messages = [] } = consultance.data();

          const messagesFormatteds = messages.map((message) => {
            const createdAt = message.created_at.toDate();
            delete message.created_at;

            return {
              createdAt,
              isHistoric: USER_ROLE.Consultant !== role,
              ...message,
            };
          });

          if (item.consultancie_id !== consultancieId) {
            historic.push(...(messagesFormatteds ?? []));
          }
        } catch (error) {
          console.error(error);
        }
      })
    );
    if (role !== 'admin') {
      dispatch(setUserHistoricMessages(historic));
    }

    dispatch(
      setUserAllConsultanciesFinished(
        consultsStarteds.length === consultanciesFinisheds.length
      )
    );
  }
);

export const getMessagesByConsultancieId =
  ({ id }) =>
  async (dispatch, getState) => {
    const { auth } = getState();

    dispatch(setLoading(true));

    onRealtime(doc(db, DB_CONSULTANCIES, id), async (document) => {
      if (document.exists()) {
        const {
          messages,
          users,
          type,
          is_open: isOpen,
          ticket_status: ticketStatus,
          created_at: last,
        } = document.data();
        let allMessages = [];

        if (messages) {
          allMessages = messages.map((item) => {
            return {
              ...item,
              status: 'read',
            };
          });

          const payload = {
            ...document.data(),
            messages: allMessages,
          };

          if (auth.currentUser.role === USER_ROLE.Consultant) {
            await updateDocument(DB_CONSULTANCIES, id, payload);
          }

          allMessages = messages.map((item) => {
            const createdAt = item.created_at.toDate();
            delete item.created_at;

            return {
              createdAt,
              ...item,
            };
          });
          dispatch(setMessages(allMessages));
        }

        if (auth.currentUser.role === USER_ROLE.Admin) {
          const user = await getDocument(DB_USERS, users.owner.id);

          dispatch(
            chatSetSelectedChat({
              name: users.owner.name,
              type,
              isOpen,
              userEmail: user?.data().email,
              id: users.owner.id,
              ticketStatus,
              last: last.toDate(),
            })
          );
        }
      } else {
        dispatch(resetConsultancie());
      }

      dispatch(setLoading(false));
    });
  };

const updateBadgeNotificationMessages = async ({ userId }) => {
  const consultReq = await getDocument(DB_CONSULTANCIES_REQUESTS, userId);

  const { not_read: notRead } = consultReq.data();

  await updateDocument(DB_CONSULTANCIES_REQUESTS, userId, {
    not_read: notRead + 1,
  });
};

export const addTextToChat =
  ({ consultancieId, userId, text }) =>
  async (dispatch, getState) => {
    if (text !== '' && text.length > 0) {
      const { auth: au } = getState();
      dispatch(setLoading(true));

      const payload = {
        updated_at: new Date(),
        messages: arrayUnion({
          created_at: new Date(),
          message: text,
          name: au.currentUser.displayName,
          sender: au.currentUser.uid,
          status: 'read',
        }),
      };

      await updateDocument(DB_CONSULTANCIES, consultancieId, payload);

      await updateBadgeNotificationMessages({ userId });

      // const user = await getDocument(DB_USERS, userId);
      // const { push_tokens: push } = user.data();

      // const notification = {
      //   title: 'Você recebeu uma nova mensagem',
      //   body: `${au.currentUser.displayName}: ${text}`,
      // };

      // dispatch(sendNotification({ notification, token: push.token }));

      dispatch(setLoading(false));
    }
  };

export const addAttachmentsToChat =
  ({ consultancieId, blob, fileName, userConsultId }) =>
  async (dispatch, getState) => {
    dispatch(setLoadingFile(true));
    const { auth } = getState();

    const userId = auth.currentUser.id;
    const path = `${consultancieId}/${userId}/${fileName}`;

    const url = await sendFile(STORAGE_CHAT_BUCKET, path, blob);

    const payload = {
      updated_at: new Date(),
      messages: arrayUnion({
        created_at: new Date(),
        file_url: url,
        name: auth.currentUser.displayName,
        sender: auth.currentUser.uid,
        status: 'read',
      }),
    };

    await updateDocument(DB_CONSULTANCIES, consultancieId, payload);

    await updateBadgeNotificationMessages({ userId: userConsultId });

    // const user = await getDocument(DB_USERS, userConsultId);
    // const { push_tokens: push } = user.data();

    // const notification = {
    //   title: 'Você recebeu um novo anexo',
    //   body: `📎 Entre no chat da sua consultoria para visualizar`,
    // };

    // dispatch(sendNotification({ notification, token: push.token }));

    dispatch(setFile(null));
    dispatch(setLoadingFile(false));
  };

export const selectChat = createAsyncThunk(
  'chat/selectChat',
  async (consultancie, { dispatch }) => {
    if (consultancie.consultancieId !== null) {
      const { id, consultancieId } = consultancie;
      dispatch(setLoading(true));
      dispatch(setFile(null));
      dispatch(setUserAllConsultanciesFinished(false));
      dispatch(setUserHistoricMessages([]));
      dispatch(setMessages([]));
      dispatch(chatSetSelectedChat(consultancie));
      await dispatch(
        getHistoricByUser({
          consultancieId,
          userId: id,
        })
      ).unwrap();
      dispatch(
        getMessagesByConsultancieId({ id: consultancie.consultancieId })
      );
      dispatch(setLoading(false));
    } else {
      dispatch(chatSetSelectedChat(null));
    }
  }
);

export const finishConsultancie = createAsyncThunk(
  'chat/finishConsultancie',
  async (props, { dispatch }) => {
    const { consultancieId, id, wasRefunded } = props;
    dispatch(setLoading(true));

    const payload = {
      is_open: false,
      updated_at: new Date(),
    };

    if (wasRefunded) payload.ticket_status = 'refunded';

    await updateDocument(DB_CONSULTANCIES, consultancieId, payload);
    await deleteDocument(DB_CONSULTANCIES_REQUESTS, id);

    const user = await getDocument(DB_USERS, id);

    const { consultancies, credits, is_prime: isPrime } = user.data();

    const currentConsultancie = consultancies.find(
      (consult) => consult.consultancie_id === consultancieId
    );

    const indexOf = consultancies.indexOf(currentConsultancie);

    currentConsultancie.finished_at = new Date();
    consultancies[indexOf] = currentConsultancie;

    const incrementCredit = wasRefunded && !isPrime;

    await setDataInDocument(
      DB_USERS,
      id,
      { consultancies, credits: incrementCredit ? credits + 1 : credits },
      { merge: true }
    );

    dispatch(resetConsultancie());
    dispatch(setLoading(false));
  }
);

const chatReducer = chatSlice.reducer;

export default chatReducer;
