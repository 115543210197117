const newAppointmentsPtMessages = {
  'newAppointments.title': 'Novos atendimentos',
  'newAppointments.ticket': 'Bilhete',
  'newAppointments.start.service': 'Iniciar',
  'newAppointments.start.new.appointments': 'Novos atendimentos',
  'newAppointments.close': 'Fechar',
  'newAppointments.requested.consultancy': 'Consultoria solicitada',
};

export default newAppointmentsPtMessages;
