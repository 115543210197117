import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDocuments,
  setDataInDocument,
  updateDocument,
  where,
} from 'services/firebase/firestore/FirestoreService';
import { DB_USERS } from 'services/firebase/firestore/FirestoreTables';
import { UserAuthService } from 'services/firebase/auth/AuthService';
import { api } from 'services/api';

export const getConsultants = createAsyncThunk(
  'consultants/getConsultant',
  async () => {
    return getDocuments(DB_USERS, where('role', '==', 'consultant'));
  }
);

export const createConsultant = createAsyncThunk(
  'consultants/createConsultant',
  async (consultant) => {
    const {
      isEditingUser = false,
      email,
      name,
      password,
      id,
      canEditPrime,
    } = consultant;
    if (!isEditingUser) {
      const user = await UserAuthService.signUp(email, name, password);

      await setDataInDocument(DB_USERS, user.uid, {
        name,
        email,
        can_edit_prime: canEditPrime,
        id: user.uid,
        role: 'consultant',
        created_at: new Date(),
      });
    } else {
      delete consultant.isEditingUser;

      await api.put(`/user/${id}`, consultant);

      await updateDocument(DB_USERS, id, {
        name,
        email,
        can_edit_prime: canEditPrime,
      });
    }
  }
);

export const deleteConsultant = createAsyncThunk(
  'consultants/deleteConsultant',
  async (id) => {
    await api.delete(`/user/${id}`);

    const infoDelete = {
      deleted: true,
      deletedAt: new Date(),
    };

    await setDataInDocument(DB_USERS, id, infoDelete, { merge: true });
  }
);

const initialState = {
  consultants: [],
  loading: false,
  loadingCreation: false,
  loadingDeleting: false,
};

const consultantsSlice = createSlice({
  name: 'consultants',
  initialState,
  extraReducers: {
    [getConsultants.pending]: (state) => {
      state.loading = true;
    },
    [getConsultants.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) state.consultants = action.payload;
    },
    [getConsultants.rejected]: (state) => {
      state.loading = false;
    },
    [createConsultant.pending]: (state) => {
      state.loadingCreation = true;
    },
    [createConsultant.fulfilled]: (state) => {
      state.loadingCreation = false;
    },
    [createConsultant.rejected]: (state) => {
      state.loadingCreation = false;
    },
    [deleteConsultant.pending]: (state) => {
      state.loadingDeleting = true;
    },
    [deleteConsultant.fulfilled]: (state) => {
      state.loadingDeleting = false;
    },
    [deleteConsultant.rejected]: (state) => {
      state.loadingDeleting = false;
    },
  },
});

const consultantsReducer = consultantsSlice.reducer;
export default consultantsReducer;
