import generateID from 'shared/constants/generateID';
import api from '../../api';

let notificationsDB = [
  {
    id: 1,
    date: '24/12/2022 - 10:45',
    publics: 'Todos',
    subject: 'Reunião Geral',
    message: 'Reunião sobre a semana',
  },
  {
    id: 2,
    date: '24/12/2022 - 10:45',
    publics: 'Todos',
    subject: 'Reunião Geral',
    message: 'Reunião sobre a semana',
  },
];

api.onGet('/api/notifications').reply(() => {
  return [200, notificationsDB];
});

api.onPost('/api/notifications').reply((request) => {
  const data = JSON.parse(request.data);

  const newNotification = {
    ...data,
    id: generateID(),
  };

  notificationsDB = [...notificationsDB, newNotification];

  return [201, newNotification];
});
export const notification = notificationsDB;
