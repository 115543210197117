import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  DB_CONSULTANCIES,
  DB_USERS,
  DB_USERS_PRIME,
} from 'services/firebase/firestore/FirestoreTables';
import { getDocuments } from 'services/firebase/firestore/FirestoreService';

export const getTotalPrimes = createAsyncThunk(
  'dashboard/getTotalPrimes',
  async () => {
    try {
      const docs = await getDocuments(DB_USERS_PRIME);
      return docs.length;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const getTotalUsers = createAsyncThunk(
  'dashboard/getTotalUsers',
  async () => {
    try {
      const docs = await getDocuments(DB_USERS);
      return docs.length;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const getTotalConsultancies = createAsyncThunk(
  'dashboard/getTotalConsultancies',
  async () => {
    try {
      return await getDocuments(DB_CONSULTANCIES);
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

const initialState = {
  primes: 0,
  users: 0,
  consultancies: 0,
  consultanciesOpened: 0,
  consultanciesEnded: 0,
  consultanciesRefunded: 0,
  loadingPrimes: false,
  loadingUsers: false,
  loadingConsultancies: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getTotalPrimes.pending]: (state) => {
      state.loadingPrimes = true;
    },
    [getTotalPrimes.fulfilled]: (state, action) => {
      state.loadingPrimes = false;
      if (action.payload) state.primes = action.payload;
    },
    [getTotalPrimes.rejected]: (state) => {
      state.loadingPrimes = false;
    },

    [getTotalUsers.pending]: (state) => {
      state.loadingUsers = true;
    },
    [getTotalUsers.fulfilled]: (state, action) => {
      state.loadingUsers = false;
      if (action.payload) state.users = action.payload;
    },
    [getTotalUsers.rejected]: (state) => {
      state.loadingUsers = false;
    },

    [getTotalConsultancies.pending]: (state) => {
      state.loadingConsultancies = true;
    },
    [getTotalConsultancies.fulfilled]: (state, action) => {
      state.loadingConsultancies = false;
      if (action.payload) {
        state.consultancies = action.payload.length;

        const consultsOpened = action.payload.filter(
          ({ is_open: isOpen }) => !!isOpen
        );

        const consultsRefunded = action.payload.filter(
          ({ ticket_status: status }) => status === 'refunded'
        );

        state.consultanciesOpened = consultsOpened.length;
        state.consultanciesEnded = state.consultancies - consultsOpened.length;
        state.consultanciesRefunded = consultsRefunded.length;
      }
    },
    [getTotalConsultancies.rejected]: (state) => {
      state.loadingConsultancies = false;
    },
  },
});

const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
