const newAppointmentsEnMessages = {
  'newAppointments.title': 'New appointments',
  'newAppointments.ticket': 'Ticket',
  'newAppointments.start.service': 'Start',
  'newAppointments.start.new.appointments': 'New appointments',
  'newAppointments.close': 'Close',
  'newAppointments.requested.consultancy': 'Requested consultancy',
};

export default newAppointmentsEnMessages;
