import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const chats = {
  chatAdmin: lazy(() => import('views/AttendanceList/AttendanceListPage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const attendanceListRoute = {
  path: `${appRoot}/attendance-list`,
  exact: true,
  icon: 'list',
  label: 'attendance.attendance.list',
  component: chats.chatAdmin,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/attendance-list`,
};

export default attendanceListRoute;
