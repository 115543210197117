import { auth } from 'settings/firebaseConfig';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  deleteUser,
  updateProfile,
} from 'firebase/auth';

class AuthService {
  signIn = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    if (userCredential?.user) {
      return userCredential.user;
    }
    throw Error('User not found or invalid!');
  };

  signUp = async (email, name, password) => {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    if (user) {
      await updateProfile(user, { displayName: name });
      return user;
    }

    throw new Error(`User ${name} not created!`);
  };

  delete = async (email, password) => {
    const { user } = await deleteUser(auth, email, password);

    if (user) return user;

    throw new Error(`User ${email} not created!`);
  };
}

export const UserAuthService = new AuthService();
