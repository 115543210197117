const AttendanceListPtMessages = {
  'attendance.attendance.list': 'Lista de atendimentos',
  'attendance.id': 'id',
  'attendance.client': 'Cliente',
  'attendance.start': 'Início',
  'attendance.end': 'Fim',
  'attendance.view.conversation': 'Visualizar conversa',
};

export default AttendanceListPtMessages;
