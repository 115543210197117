import axios from 'axios';

axios.defaults.headers.post['content-type'] = 'appication/json';

const api = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: process.env.REACT_APP_API_FIREBASE_SERVER,
});

const apiSendNotification = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_URL_API,
  headers: { Authorization: process.env.REACT_APP_NOTIFICATION_KEY },
});

export { api, apiSendNotification };
