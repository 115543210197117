import { storage } from 'settings/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const sendFile = async (bucket, path, blob) => {
  const mountLocation = `${bucket}/${path}`;
  const remoteReference = ref(storage, mountLocation);

  await uploadBytes(remoteReference, blob);
  return getDownloadURL(remoteReference);
};

export { sendFile };
