import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { DEFAULT_USER, IS_DEMO } from 'config.js';
import { UserAuthService } from 'services/firebase/auth/AuthService';
import { getDocument } from 'services/firebase/firestore/FirestoreService';
import { DB_USERS } from 'services/firebase/firestore/FirestoreTables';

const initialState = {
  isLogin: IS_DEMO,
  currentUser: IS_DEMO ? DEFAULT_USER : {},
};

const rolesGaranted = ['admin', 'consultant'];

export const fetchAuth = createAsyncThunk(
  'user/auth',
  async ({ email, password }) => {
    try {
      const user = await UserAuthService.signIn(email, password);

      const docSnap = await getDocument(DB_USERS, user.uid);

      let roleUser = 'customer';
      let editPrime = false;

      if (docSnap.exists()) {
        const { role, can_edit_prime: canEditPrime } = docSnap.data();
        if (!rolesGaranted.includes(role)) {
          return { success: false };
        }
        roleUser = role;
        editPrime = canEditPrime;
      }

      return {
        success: true,
        data: { role: roleUser, canEditPrime: editPrime, ...user },
      };
    } catch (error) {
      return { success: false };
    }
  }
);

const authEntity = createEntityAdapter({});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      state.isLogin = !!action.payload?.id;
    },
  },
  extraReducers: {
    [fetchAuth.fulfilled]: authEntity.setAll,
    // [getUser.fulfilled]: authEntity.setOne,
  },
});
export const { setCurrentUser } = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
