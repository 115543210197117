const loginEnMessages = {
  'login.welcome': 'Welcome{symbol}',
  'login.get.started': "let's get started{symbol}",
  'login.input.password.placeholder': 'Password',
  'login.input.email.placeholder': 'Email',
  'login.enter': 'Login',
  'login.welcome.message': 'Please use your credentials to login.',
  'login.input.email.validation': 'Please provide a valid email',
  'login.input.email.validation.required': 'Email field is required',
  'login.input.password.validation.min.length':
    'Password must be at least 6 digits',
  'login.input.password.validation.required': 'Password field is required',
  'login.error.user.not.found': 'User not found or invalid credentials!',
  'login.success.message': 'Authentication successful.',
  'login.generic.error.message': 'An unexpected event has occurred! Try again.',
};

export default loginEnMessages;
