const consultantsPtMessages = {
  'consultants.can.edit.users.prime': 'Pode editar Usuários Prime',
  'consultants.can.edit.users.prime.description':
    'Se você habilitar a opção acima, esse consultor(a) poderá gerenciar os usuários Prime do aplicativo.',
  'consultants.consultant': 'Consultor',
  'consultants.start': 'Início',
  'consultants.views.chats': 'Ver conversas',
  'consultants.attendances': 'Atendimentos',
  'consultants.full.name': 'Nome completo',
  'consultants.birth.date': 'Data de nascimento',
  'consultants.email': 'E-mail',
  'consultants.add.new.consultant': 'Adicionar novo consultor',
  'consultants.basic.information': 'Informações básicas',
  'consultants.contacts': 'Contatos',
  'consultants.photo': 'Foto',
  'consultants.cell': 'Celular',
  'consultants.phone': 'Telefone (Opicional)',
  'consultants.edit.consultant': 'Editar consultor',
  'consultants.delete.title': 'Excluir consultor',
  'consultants.delete.description':
    'Você deseja realmente excluir <b>{name}</b>?',
};

export default consultantsPtMessages;
