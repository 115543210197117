const chatPtMessages = {
  'chat.add.contact': 'Adicionar Contato',
  'chat.chat': 'Bate-papo',
  'chat.contacts': 'Contatos',
  'chat.call': 'Ligar',
  'chat.message': 'Mensagem',
  'chat.input.message.hint.disable':
    'Essa consultoria foi finalizada! Não será permitido o envio de novas mensagens.',
  'chat.messages': 'Mensagens',
  'chat.video.call': 'Vídeo Chamada',
  'chat.finish.title': 'Finalizar consultoria',
  'chat.finished': 'Finalizado',
  'chat.is.open': 'Em aberto',
  'chat.refunde.credit': 'Estornar créditos',
  'chat.close.button': 'Voltar',
  'chat.finish.description': 'Obrigado por ajudar o cliente.',
  'chat.finish': 'Concluir',
  'chat.return.service.order': 'Devolver ordem de serviço',
  'chat.return.service.order.description':
    'Para estornar os créditos da consultoria, informe abaixo o motivo do estorno',
  'chat.return.service.order.feature.building': 'Funcionalidade em construção!',
  'chat.last.updated': 'Última atualização',
  'chat.no.services.requested': 'Nenhum atendimento solicitado',
  'chat.select.message.description':
    'Selecione um cliente na lista ao lado para realizar a consultoria.',
  'chat.select.message.description.admin':
    'Selecione um cliente na lista de atendimentos para visualizar a consultoria.',
  'chat.select.message.description.starting':
    'Envie uma mensagem abaixo para iniciar o atendimento com o cliente.',
  'chat.attendance.list': 'Lista de atendimentos',
  'chat.was.refunded': 'Estornado',
};

export default chatPtMessages;
