import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const notifications = {
  notification: lazy(() => import('views/notification/NotificationPage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const notificationsRoute = {
  path: `${appRoot}/notifications`,
  exact: true,
  icon: 'bell',
  label: 'notification.notifications',
  component: notifications.notification,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/notifications`,
};

export default notificationsRoute;
