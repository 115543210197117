const notificationsPtMessages = {
  'notification.add': 'Criar',
  'notification.addNotifications': 'Criar Notificação',
  'notification.message': 'Mensagem',
  'notification.newMessage': 'Nova mensagem',
  'notification.notifications': 'Notificações',
  'notification.errorMessage': 'Ocorreu um erro! Tente novamente',
  'notification.successMessage': 'Os dados foram criados com sucesso,',
  'notification.period': 'Período',
  'notification.toProgramed': 'Programado para',
  'notification.public': 'Público',
  'notification.targetAudience': 'Público-alvo',
  'notification.save': 'Enviar',
  'notification.situation': 'Situação',
  'notification.block': 'Bloquear',
  'notification.consultacie.modal.title': 'Consultoria Solicitada',
  'notification.consultacie.modal.customer': 'Cliente',
  'notification.consultacie.modal.subject': 'Assunto',
  'notification.consultacie.modal.created.at': 'Data da solicitação',
  'notification.consultacie.modal.button.confirm': 'Iniciar atendimento',
  'notification.consultacie.modal.button.close': 'Fechar',
  'notification.see.all': 'Ver todos',
};

export default notificationsPtMessages;
