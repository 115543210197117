import commonsEnMessages from 'i18n/messages/commons/commonsEn';
import notificationsEnMessages from 'i18n/messages/notifications/notificationsEn';
import loginEnMessages from 'i18n/messages/login/loginEn';
import validationEnMessages from 'i18n/messages/validate/ValidationEn';
import consultantsEnMessages from 'i18n/messages/consultants/consultantsEn';
import chatEnMessages from 'i18n/messages/chat/chatEn';
import newAppointmentsEnMessages from 'i18n/messages/newAppointments/newAppointmentsEn';
import AttendanceListEnMessages from 'i18n/messages/AttendanceList/AttendanceListEn';
import dashboardsEnMessages from 'i18n/messages/dashboards/dashboardsEn';
import menuEnMessages from 'i18n/messages/menu/menuEn';
import primeEnMessages from 'i18n/messages/prime/primeEn';

const enMessages = {
  ...commonsEnMessages,
  ...notificationsEnMessages,
  ...loginEnMessages,
  ...validationEnMessages,
  ...consultantsEnMessages,
  ...chatEnMessages,
  ...newAppointmentsEnMessages,
  ...AttendanceListEnMessages,
  ...dashboardsEnMessages,
  ...menuEnMessages,
  ...primeEnMessages,
};

export default enMessages;
