// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';

// import theme reducers
import settingsReducer from 'settings/settingsSlice';
import layoutReducer from 'layout/layoutSlice';
import authReducer from 'auth/authSlice';
import langReducer from 'i18n/langSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import notificationsReducer from 'views/notification/notificationsSlice';
import attendanceReducer from 'views/AttendanceList/attendanceSlice';
import dashboardSlice from 'views/dashboards/dashboardSlice';
// import persist key
import { REDUX_PERSIST_KEY } from 'config.js';
import chatReducer from 'views/chat/chatSlice';
import consultantsReducer from 'views/consultants/consultantsSlice';
import primeReducer from 'views/prime/primeSlice';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'settings', 'lang', 'auth'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
    lang: langReducer,
    auth: authReducer,
    menu: menuReducer,
    notification: notificationReducer,
    scrollspy: scrollspyReducer,
    notifications: notificationsReducer,
    attendance: attendanceReducer,
    chat: chatReducer,
    consultants: consultantsReducer,
    prime: primeReducer,
    dashboard: dashboardSlice,
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
