const commonsPtMessages = {
  'commons.data.not.found': 'Não há registro',
  'commons.we.did.not.find.data.for': 'Não encontramos dados de busca para',
  'commons.information.not.found': 'Informações não encontradas.',
  'commons.edit': 'Editar',
  'commons.home': 'Início',
  'commons.add': 'Adicionar',
  'commons.save': 'Salvar',
  'commons.deleted': 'Excluído',
  'commons.optional': 'Opcional',
  'commons.situation': 'Situação',
  'commons.waiting': 'Aguardando',
  'commons.cancel': 'Cancelar',
  'commons.delete': 'Excluir',
  'commons.reset.password': 'Resetar senha',
  'commons.consultants': 'Consultores',
  'commons.consultant': 'Consultor',
  'commons.search': 'Pesquisar',
  'commons.items': 'Itens',
  'commons.name': 'Nome',
  'commons.subject': 'Assunto',
  'commons.date': 'Data',
  'commons.email': 'E-mail',
};

export default commonsPtMessages;
