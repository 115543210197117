import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  DB_USERS,
  DB_USERS_PRIME,
} from 'services/firebase/firestore/FirestoreTables';
import {
  deleteDocument,
  getDocuments,
  orderBy,
  setDataInDocument,
  updateDocument,
  where,
} from 'services/firebase/firestore/FirestoreService';

export const getUsersPrime = createAsyncThunk(
  'prime/getUsersPrime',
  async () => {
    try {
      return getDocuments(DB_USERS_PRIME, orderBy('name'));
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const deleteUserPrime = createAsyncThunk(
  'prime/deleteUserPrime',
  async ({ id }) => {
    try {
      await deleteDocument(DB_USERS_PRIME, id);
      const users = await getDocuments(DB_USERS, where('email', '==', id));

      if (users.length) {
        await updateDocument(DB_USERS, users[0].id, { is_prime: false });
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateUserPrime = createAsyncThunk(
  'prime/updateUserPrime',
  async ({ oldUser, newUser }) => {
    try {
      if (oldUser && newUser.email !== oldUser.email) {
        await deleteDocument(DB_USERS_PRIME, oldUser.email);
      }
      await setDataInDocument(DB_USERS_PRIME, newUser.email, newUser);
      const users = await getDocuments(
        DB_USERS,
        where('email', '==', newUser.email)
      );

      if (users.length) {
        await updateDocument(DB_USERS, users[0].id, { is_prime: true });
      }
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState = {
  prime: [],
  loading: false,
  deleting: false,
  updating: false,
};

const primeSlice = createSlice({
  name: 'prime',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDeleting: (state, action) => {
      state.deleting = action.payload;
    },
    setUpdating: (state, action) => {
      state.updating = action.payload;
    },
  },
  extraReducers: {
    [getUsersPrime.pending]: (state) => {
      state.loading = true;
    },
    [getUsersPrime.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) state.prime = action.payload;
    },
    [getUsersPrime.rejected]: (state) => {
      state.loading = false;
    },
    [deleteUserPrime.pending]: (state) => {
      state.deleting = true;
    },
    [deleteUserPrime.fulfilled]: (state) => {
      state.deleting = false;
    },
    [deleteUserPrime.rejected]: (state) => {
      state.deleting = false;
    },
    [updateUserPrime.pending]: (state) => {
      state.updating = true;
    },
    [updateUserPrime.fulfilled]: (state) => {
      state.updating = false;
    },
    [updateUserPrime.rejected]: (state) => {
      state.updating = false;
    },
  },
});

export const { setLoading } = primeSlice.actions;

const primeReducer = primeSlice.reducer;
export default primeReducer;
