const dashboardsEnMessages = {
  'dashboards.welcome': 'Welcome{symbol}',
  'dashboards.total.consultancies': 'Total consultancies',
  'dashboards.total.users': 'Total users',
  'dashboards.total.prime.list': 'Prime list',
  'dashboards.opened': 'Attendancies opened',
  'dashboards.ended': 'Attendancies ended',
  'dashboards.billing': 'Billing',
  'dashboards.sales': 'Sales',
  'dashboards.consultants': 'Consultants',
  'dashboards.returns': 'Total refunded',
  'dashboards.comments': 'Comments',
  'dashboards.purchasing': 'Purchasing',
  'dashboards.called': 'Called',
  'dashboards.pending': 'Pending',
  'dashboards.shipped': 'Shipped',
};

export default dashboardsEnMessages;
