const chatEnMessages = {
  'chat.add.contact': 'Add Contact',
  'chat.call': 'Call',
  'chat.chat': 'Chat',
  'chat.contacts': 'Contacts',
  'chat.message': 'Message',
  'chat.messages': 'Messages',
  'chat.input.message.hint.disable':
    'This consultancy is finished! The sending of new messages will not be allowed.',
  'chat.video.call': 'Video Call',
  'chat.finish.title': 'Finish consultancie',
  'chat.finished': 'Finished',
  'chat.is.open': 'In progress',
  'chat.close.button': 'Close',
  'chat.finish.description': 'Thank you for helping the customer.',
  'chat.refunde.credit': 'Refunde credits',
  'chat.finish': 'Finish',
  'chat.return.service.order': 'Return service order',
  'chat.return.service.order.description':
    'To reverse the consulting credits, enter the reason for the reversal below.',
  'chat.return.service.order.feature.building': 'Feature is building!',
  'chat.last.updated': 'Last updated',
  'chat.no.services.requested': 'No service requested',
  'chat.select.message.description':
    'Select a customer from the list on the side to carry out the consultancie.',
  'chat.select.message.description.admin':
    'Select a customer from the attendance list to view the consultancie.',
  'chat.select.message.description.starting':
    'Send a message below to initiate customer service.',
  'chat.attendance.list': 'Attendance list',
  'chat.was.refunded': 'Refunded',
};

export default chatEnMessages;
