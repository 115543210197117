import axios from 'axios';
import { collection, query, where, getDocs } from '@firebase/firestore';

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { apiSendNotification } from 'services/api';
import { db } from 'settings/firebaseConfig';
import { DB_NOTIFICATIONS } from 'services/firebase/firestore/FirestoreTables';

export const getNotifications = createAsyncThunk(
  'notifications/getNotification',
  async (_, { getState }) => {
    const { auth } = getState();
    const notificationsCollectionRef = collection(db, DB_NOTIFICATIONS);

    const queryNotificationsByUserUId = query(
      notificationsCollectionRef,
      where('uid', '==', auth.currentUser.uid)
    );
    const data = await getDocs(queryNotificationsByUserUId);
    const notifications = data.docs.map((notification) => {
      return { id: notification.id, ...notification.data() };
    });
    return notifications;
  }
);

export const createNotification = createAsyncThunk(
  'notifications/createNotification',
  async (notifications) => {
    const response = await axios.post('/api/notifications', notifications);
    const data = await response.data;

    return data;
  }
);

export const sendNotification = createAsyncThunk(
  'notifications/sendNotification',
  async ({ notification, token }) => {
    try {
      const prodMode = process.env.REACT_APP_ENV !== 'development';

      const payload = {
        notification,
        to:
          token ??
          'cjzo9ap4-UZYt6Z-adJBEM:APA91bGwwA2JoPbu_19QyZ4MrxrGV--S-4GVDoUUnf1ezgeVzO9ixU87Z1L6VCX9AizkH0eAqsHna-5oiw_-XyBIW3Vwov7eM5wgyAnln2DqPiJYOF8tjrK-ta-lDgTQcF1MhD_Z-0DR', // TOKEN MICHAEL IPHONE XR
      };

      if (prodMode) {
        delete payload.to;
        payload.condition = "'customers' in Topics";
      }

      const response = await apiSendNotification.post('/send', payload);

      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

const notificationAdapter = createEntityAdapter({});

export const {
  selectAll: selectNotification,
  selectEntities: selectNotificationEntities,
  selectById: selectNotificationById,
} = notificationAdapter.getSelectors((state) => state.notifications);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: {
    [getNotifications.fulfilled]: notificationAdapter.setAll,
    [createNotification.fulfilled]: notificationAdapter.addOne,
  },
});

export const { setLoading } = notificationsSlice.actions;

export default notificationsSlice.reducer;
