import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const chats = {
  chat: lazy(() => import('views/chat/Chat')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const chatRoute = {
  path: `${appRoot}/chat`,
  exact: true,
  icon: 'messages',
  label: 'chat.chat',
  component: chats.chat,
  protected: true,
  // hideInMenu: true,
  roles: [USER_ROLE.Admin, USER_ROLE.Consultant],
  to: `${appRoot}/chat`,
};

export default chatRoute;
