import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { DB_CONSULTANCIES } from 'services/firebase/firestore/FirestoreTables';
import {
  getDocuments,
  orderBy,
} from 'services/firebase/firestore/FirestoreService';

export const getAttendancies = createAsyncThunk(
  'attendance/getAttendancies',
  async () => {
    try {
      return getDocuments(DB_CONSULTANCIES, orderBy('created_at', 'desc'));
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

const initialState = {
  attendancies: [],
  loading: false,
};

const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getAttendancies.pending]: (state) => {
      state.loading = true;
    },
    [getAttendancies.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) state.attendancies = action.payload;
    },
    [getAttendancies.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setLoading } = attendanceSlice.actions;

const attendanceReducer = attendanceSlice.reducer;
export default attendanceReducer;
