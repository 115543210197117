const commonsEnMessages = {
  'commons.data.not.found': 'Data not found',
  'commons.we.did.not.find.data.for': "We didn't find data for",
  'commons.information.not.found': 'Information not found.',
  'commons.edit': 'Edit',
  'commons.home': 'Homepage',
  'commons.add': 'Add',
  'commons.save': 'Save',
  'commons.cancel': 'Cancel',
  'commons.deleted': 'Deleted',
  'commons.situation': 'Status',
  'commons.waiting': 'Waiting',
  'commons.optional': 'Optional',
  'commons.delete': 'Delete',
  'commons.reset.password': 'Reset password',
  'commons.consultants': 'Consultants',
  'commons.consultant': 'Consultant',
  'commons.search': 'Search',
  'commons.items': 'Items',
  'commons.name': 'Name',
  'commons.subject': 'Subject',
  'commons.date': 'Date',
  'commons.email': 'E-mail',
};

export default commonsEnMessages;
