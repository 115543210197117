import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const services = {
  newService: lazy(() => import('views/newAppointments/NewAppointmentsPage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const newAppointmentsRoute = {
  path: `${appRoot}/consultancies-requests`,
  exact: true,
  icon: 'headset',
  label: 'newAppointments.start.new.appointments',
  component: services.newService,
  protected: true,
  roles: [USER_ROLE.Consultant],
};

export default newAppointmentsRoute;
