const loginPtMessages = {
  'login.welcome': 'Bem vindo{symbol}',
  'login.get.started': 'vamos começar{symbol}',
  'login.input.password.placeholder': 'Senha',
  'login.input.email.placeholder': 'Email',
  'login.enter': 'Entrar',
  'login.welcome.message': 'Por favor entre com suas credenciais.',
  'login.input.email.validation': 'Informe um E-mail válido',
  'login.input.email.validation.required': 'Campo de E-mail é obrigatório',
  'login.input.password.validation.min.length':
    'A senha deve ter no mínimo 6 dígitos',
  'login.input.password.validation.required': 'Campo de Senha é obrigatório',
  'login.error.user.not.found':
    'Usuário não encontrado ou credenciais inválidas!',
  'login.success.message': 'Autenticação realizada com sucesso.',
  'login.generic.error.message': 'Ocorreu um erro inesperado! Tente novamente.',
};

export default loginPtMessages;
